import { ofType, combineEpics, } from 'redux-observable';
import { pluck, filter, flatMap, } from 'rxjs/operators';

import { actions, } from '@ezugi/bootstrap';
import { LAST_BETS, } from '@ezugi/constants';

import handleLastBets from './handleLastBets';


const {
  roundActions: { round, },
} = actions;

function lastBetsRoundEpic(action$, state$) {
  return action$.pipe(
    ofType(round.set),
    pluck('payload'),
    filter(({ roundStatus, }) => roundStatus === LAST_BETS),
    flatMap(() => handleLastBets(state$.value))
  );
}

export default combineEpics(
  lastBetsRoundEpic,
);
