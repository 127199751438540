import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { numberOfCardsSelector, } from '../../selectors/cards';
import { localizedRoundStepSelector, } from '../../selectors/round';
import { isHandheld, } from '../../../utils';

const {
  uiActions: { progressBar, },
} = actions;

export default function handlePlaceYourBets(state) {
  const stepNumber = numberOfCardsSelector(state) + 1;
  const stepLabel = localizedRoundStepSelector(stepNumber)(state);

  return of(
    ...(isHandheld ? [ progressBar.set({
      label: `LAST BETS FOR ${stepLabel}`,
    }), ] : [])
  );
}
