import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';

import { numberOfCardsSelector, } from '../../selectors/cards';
import { localizedRoundStepSelector, } from '../../selectors/round';

const {
  uiActions: { progressBar, },
} = actions;

export default function handleNoMoreBets(state) {
  const stepNumber = numberOfCardsSelector(state) + 1;
  const stepLabel = localizedRoundStepSelector(stepNumber)(state);

  return of(
    progressBar.set({
      label: `Dealing ${stepLabel}`,
    }),
  );
}
