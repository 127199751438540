import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { gameResultsSelector, } from '../../selectors/game';
import { numberOfCardsSelector, } from '../../selectors/cards';
import { localizedRoundStepSelector, } from '../../selectors/round';
import { isHandheld, } from '../../../utils';

const {
  gameActions: { game, },
  uiActions: { progressBar, },
} = actions;

export default function handlePlaceYourBets(state) {
  const stepNumber = numberOfCardsSelector(state) + 1;
  const stepLabel = localizedRoundStepSelector(stepNumber)(state);

  return of(
    game.set({
      gameResults: { ...gameResultsSelector(state), wonSidebets: [], },
    }),
    (isHandheld ? progressBar.set({
      label: `BETS FOR ${stepLabel}`,
    }) : progressBar.reset())
  );
}
