import { createSelector, } from 'reselect';
import { pathOr, } from 'ramda';

import { selectors, } from '@ezugi/bootstrap';

const { localeSelector, } = selectors;

const localeKeyBasis = '1dtp_ui.round_step_';

export const localizedRoundStepSelector = (stepNumber) => createSelector(
  localeSelector,
  (locale) => {
    const localeKey = `${localeKeyBasis}${stepNumber}`;
    return pathOr(localeKey, localeKey.split('.'))(locale);
  },
);
